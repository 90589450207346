var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "agencies-list"
    }
  }, [_c('AgenciesListFilters', {
    attrs: {
      "status-agency-filter": _vm.statusAgencyFilter,
      "status-active-filter": _vm.statusActiveFilter,
      "agency-manager-filter": _vm.agencyManagerFilter,
      "status-agency-options": _vm.statusAgencyOptions,
      "status-active-options": _vm.statusActiveOptions,
      "empty-filter": _vm.isEmptyFilter
    },
    on: {
      "update:statusAgencyFilter": function updateStatusAgencyFilter($event) {
        _vm.statusAgencyFilter = $event;
      },
      "update:status-agency-filter": function updateStatusAgencyFilter($event) {
        _vm.statusAgencyFilter = $event;
      },
      "update:statusActiveFilter": function updateStatusActiveFilter($event) {
        _vm.statusActiveFilter = $event;
      },
      "update:status-active-filter": function updateStatusActiveFilter($event) {
        _vm.statusActiveFilter = $event;
      },
      "update:agencyManagerFilter": function updateAgencyManagerFilter($event) {
        _vm.agencyManagerFilter = $event;
      },
      "update:agency-manager-filter": function updateAgencyManagerFilter($event) {
        _vm.agencyManagerFilter = $event;
      },
      "fetch-data": _vm.refetchData,
      "reset": _vm.clearFilter
    }
  }), _c('b-card', {
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, [_c('span', {
    staticClass: "text-muted ml-50"
  }, [_vm._v(_vm._s(_vm.$t('agency.agencies')))])])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-none d-lg-flex"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('agency.createAgency')
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-agencies-add'
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('agency.addAgency')) + " ")], 1)]), _c('b-button', {
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('agency.exportAgency')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('export')) + " ")], 1)])], 1), _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('b-dropdown', {
    staticClass: "m-lg-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('agency.moreDropdown.title')))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('agency.createAgency')
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-agencies-add'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.moreDropdown.add')) + " ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": !_vm.canAccess('agency.exportAgency')
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.moreDropdown.export')) + " ")])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-input-group', {
    staticClass: "w-100 mr-md-1",
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('agency.placeholderSearch'),
      "value": _vm.searchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "disabled": !_vm.canAccess('agency.searchAgency'),
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:searchTextFilter', val);
      }
    },
    model: {
      value: _vm.searchTextFilter,
      callback: function callback($$v) {
        _vm.searchTextFilter = $$v;
      },
      expression: "searchTextFilter"
    }
  })], 1)], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refAgenciesListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchAgencies,
      "responsive": "",
      "fields": _vm.tableColumnsAgency,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": _vm.$t('noRecordFund'),
      "sort-desc": _vm.isSortDirDesc,
      "no-border-collapse": ""
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsAgency, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "70",
                  "src": data.item.avatar,
                  "text": _vm.avatarText("".concat(data.item.agencyName)),
                  "variant": "light-primary"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('h5', [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.agencyName) + " ")])]), _c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "size": "14",
            "icon": "TagIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.agencyOwner.name || 'N/A') + " ")])], 1), _c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.emailAddress || 'N/A'))])], 1)])];
      }
    }, {
      key: "cell(balance)",
      fn: function fn(_ref) {
        var creditInfo = _ref.item.creditInfo;
        return [_c('div', {
          class: creditInfo.isOverLimit || creditInfo.isWarningLimitPercent ? 'text-danger' : creditInfo.isWarning ? 'text-warning' : 'text-success',
          staticStyle: {
            "white-space": "nowrap"
          }
        }, [_c('span', [_vm._v("Công nợ:")]), _vm._v(" " + _vm._s(_vm.formatCurrency(creditInfo.balance)) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', [_vm._v("Hạn mức:")]), _vm._v(" " + _vm._s(_vm.formatCurrency(creditInfo.limitCredit)) + " ")])];
      }
    }, {
      key: "cell(agencyCode)",
      fn: function fn(data) {
        return [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
          attrs: {
            "disabled": !_vm.canAccess('agency.detailAgency'),
            "to": {
              name: 'apps-agencies-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.agencyCode.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(manager)",
      fn: function fn(data) {
        return [data.item.agencyManager ? _c('p', {
          staticClass: "text-nowrap mb-0"
        }, [_vm._v(" " + _vm._s("".concat(data.item.agencyManager.lastName, " ").concat(data.item.agencyManager.firstName)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), data.item.createdBy ? _c('small', [_vm._v(" " + _vm._s("".concat(data.item.createdBy.firstName, " ").concat(data.item.createdBy.lastName)) + " ")]) : _vm._e()])], 1)];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")]), data.item.updatedBy ? _c('small', [_vm._v(" " + _vm._s("".concat(data.item.updatedBy.firstName, " ").concat(data.item.updatedBy.lastName)) + " ")]) : _vm._e()])], 1)];
      }
    }, {
      key: "cell(isActive)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_vm.canAccess('agency.updateAgency') && data.item.id !== _vm.agencyDataAgId ? _c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeActive(data.item);
            }
          },
          model: {
            value: data.item.isActive,
            callback: function callback($$v) {
              _vm.$set(data.item, "isActive", $$v);
            },
            expression: "data.item.isActive"
          }
        }) : _c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 custom-control-success",
          attrs: {
            "checked": data.item.isActive,
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": ""
          }
        })], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-badge', {
          staticClass: "badge-glow text-uppercase",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.resolveAgencyStatus(data.item.status, 'value', 'label'))) + " ")])], 1)];
      }
    }, {
      key: "cell(retail)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeEnableRetail(data.item);
            }
          },
          model: {
            value: data.item.enableRetail,
            callback: function callback($$v) {
              _vm.$set(data.item, "enableRetail", $$v);
            },
            expression: "data.item.enableRetail"
          }
        })], 1), data.item ? _c('BButton', {
          staticClass: "p-50",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.giftButtonHandle(data.item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1 flex-nowrap"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "GiftIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm.$t('packageConfig.agencyPackageConfigsGift')))])], 1)]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(enableInvoice)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeEnableInvoice(data.item);
            }
          },
          model: {
            value: data.item.enableInvoice,
            callback: function callback($$v) {
              _vm.$set(data.item, "enableInvoice", $$v);
            },
            expression: "data.item.enableInvoice"
          }
        })], 1)])];
      }
    }, {
      key: "cell(enableInvoiceRequest)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeEnableInvoiceRequest(data.item);
            }
          },
          model: {
            value: data.item.enableInvoiceRequest,
            callback: function callback($$v) {
              _vm.$set(data.item, "enableInvoiceRequest", $$v);
            },
            expression: "data.item.enableInvoiceRequest"
          }
        })], 1)])];
      }
    }, {
      key: "cell(enableTour)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeEnableTour(data.item);
            }
          },
          model: {
            value: data.item.enableTour,
            callback: function callback($$v) {
              _vm.$set(data.item, "enableTour", $$v);
            },
            expression: "data.item.enableTour"
          }
        })], 1)])];
      }
    }, {
      key: "cell(enableTrain)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 d-flex-center gap-2"
        }, [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeEnableTrain(data.item);
            }
          },
          model: {
            value: data.item.enableTrain,
            callback: function callback($$v) {
              _vm.$set(data.item, "enableTrain", $$v);
            },
            expression: "data.item.enableTrain"
          }
        })], 1)])];
      }
    }, {
      key: "cell(agencyFee)",
      fn: function fn(data) {
        return [_vm.isRoleF2 || _vm.isRoleF1 && _vm.isType ? _c('b-button', {
          staticClass: "p-50 d-flex-center",
          staticStyle: {
            "min-width": "110px"
          },
          attrs: {
            "variant": data.item.applyAgencyFee ? 'outline-warning' : 'outline-info',
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.openModalUpdateAgencyFee(data.item);
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.applyAgencyFee ? 'Thay đổi phí xuất vé riêng' : 'Áp dụng phí xuất vé riêng') + " ")]) : _vm.isRoleF1 && !_vm.isType ? _c('b-button', {
          staticClass: "p-50 d-flex-center",
          staticStyle: {
            "min-width": "110px"
          },
          attrs: {
            "variant": data.item.applyAgencyFee ? 'outline-warning' : 'outline-info',
            "size": "sm",
            "disabled": !data.item.applyAgencyFee
          },
          on: {
            "click": function click($event) {
              return _vm.openModalUpdateAgencyFee(data.item);
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.applyAgencyFee ? 'Đã cài phí xuất vé riêng' : 'Chưa cài phí xuất vé riêng') + " ")]) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalAgencies,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('ModalUpdateAgencyFee', {
    attrs: {
      "agencies-data": _vm.agenciesDataToUpdateAgencyFee
    },
    on: {
      "refetchAgency": _vm.refetchData
    }
  }), _c('ModalBuy', {
    attrs: {
      "agency-item": _vm.agencyItem,
      "is-gift": true,
      "disabled-agency-item": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }